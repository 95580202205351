export default {
  init() {
    // JavaScript to be fired on all pages

    // Navigation Toggle
    document.getElementById('nav-toggle').addEventListener('click', function(e) {
      e.preventDefault();
      this.classList.toggle('is-active');
      document.getElementById('nav-primary').classList.toggle('is-active');
      document.getElementById('nav-curtains').classList.toggle('is-active');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after init JS is fired

    // Add class to .header when scrolled one pixel from the top
    window.addEventListener('scroll', () => {
      const scrolled = window.scrollY;
      const header = document.querySelector('header');

      if (scrolled > 0) {
        header.classList.add('is-scroll');
      } else {
        header.classList.remove('is-scroll');
      }
    });
  },
};
